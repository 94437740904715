<template>
  <v-btn
    :loading="downloading"
    :color="this.color"
    :text="text"
    :outlined="outlined"
    :depressed="depressed"
    :small="small"
    @click="downloadDocument"
  >
    {{ cta }} <v-icon size="20" class="ml-2">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import { apiClientBlob } from "@/services/ApiClient";
import { downloadFile } from "@/utils/helpers";

export default {
  props: {
    endpointUrl: { type: String, required: true },
    color: { type: String, default: "info" },
    cta: { type: String, default: "Descargar" },
    icon: { type: String, default: "mdi-download" },
    text: { type: Boolean, default: false },
    depressed: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
  },
  data: () => ({
    downloading: null,
  }),
  methods: {
    async downloadDocument() {
      try {
        this.downloading = true;
        const response = await apiClientBlob.get(this.endpointUrl);
        downloadFile(response);
      } catch (e) {
        this.$notifyError(undefined, "Error al descargar el archivo | " + e);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
